import { Heading, Stack } from '@chakra-ui/react';
import React from 'react';
import { Page } from '../components/Page';
import { seo } from '../components/Seo';

export const Head = seo({ title: 'Home' });

export default function IndexPage() {
  return (
    <Page>
      <Stack spacing={4} flex="1 1 auto" align="center" justify="center" minH={40}>
        <Heading fontSize="6xl" fontWeight="extrabold" textAlign="center">
          Coming soon&#8482;
        </Heading>
      </Stack>
    </Page>
  );
}
